<template>
  <div>
    <label
      for="file"
      class="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out border border-transparent rounded-md shadow-sm cursor-pointer hover:bg-red-500 focus:outline-none focus:border-red-700 focus:ring-red sm:text-sm sm:leading-5"
      :class="{
        ' bg-indigo-500 hover:bg-indigo-600': !status,
        ' bg-orange-500 hover:bg-orange-600': status === 'busy',
        ' bg-green-500 hover:bg-green-600': status === 'success',
      }"
    >
      <div v-if="!status" class="flex items-center">
        <div class="ml-2">{{ labels.empty }}</div>
      </div>
      <div v-if="status == 'busy'" class="flex items-center">
        <svg
          class="w-4 h-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
          />
        </svg>
        <div class="ml-2">{{ labels.busy }}</div>
      </div>
      <div v-if="status == 'success'" class="flex items-center">
        <svg
          class="w-4 h-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <div class="ml-2">{{ labels.success }}</div>
      </div>
    </label>
    <input
      type="file"
      name="file"
      id="file"
      @change="upload"
      class="hidden form-control-file"
    />
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    labels: {
      type: Object,
      default: () => {
        return {
          empty: "Enviar Recibo / Comprobante",
          busy: "Estamos procesando tu comprobante",
          success: "Recibimos tu comprobante",
        };
      },
    },
  },
  computed: {
    status() {
      return this.value;
    },
  },
  mounted() {
    const self = this;
    this.reader = new FileReader();
    this.reader.addEventListener(
      "load",
      function (e) {
        self.$emit("input", "busy");
        var form = new FormData();
        form.append("file", e.target.result);
        form.append("upload_preset", "ml_default");
        axios({
          url: `https://api.cloudinary.com/v1_1/de3yblyup/image/upload`,
          method: "POST",
          async: true,
          crossDomain: true,
          headers: {
            "Content-Type": undefined,
            "X-Requested-With": "XMLHttpRequest",
            "cache-control": "no-cache",
          },
          mimeType: "multipart/form-data",
          data: form,
        })
          .then((res) => {
            if (res.status === 200) {
              self.$emit("uploaded", res.data.url);
              //   this.imageUrl = res.data.url;
            } else {
              console.info("oops, something went wrong", res);
              self.$emit("input", "error");
            }
          })
          .catch((err) => {
            console.log("ERROR");
            console.error(err);
          });
      },
      false
    );
  },
  methods: {
    //   ...mapActions("uploads", { create: "create" }),
    upload(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      this.reader.readAsDataURL(files[0]);
    },
  },
};
</script>
